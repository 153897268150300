import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { FirestoreContext } from "./FirestoreContext";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const { dutyOps } = useContext(FirestoreContext);
  const [user, setUser] = useState();
  const cookies = new Cookies();

  const ROLE_ADMIN_UUID = "aced337c-bd81-4b24-acf7-539e3be3fa94";
  const ROLE_STUDENT_UUID = "13943742-bb71-456b-a25c-cd0811db4c5c";

  const handleSetUser = (user) => {
    let role;
    if (user.role === "admin") role = ROLE_ADMIN_UUID;
    if (user.role === "student") role = ROLE_STUDENT_UUID;

    const userWithRoleUUID = {
      user: user.username,
      role,
    };
    cookies.set("user", userWithRoleUUID, { path: "/" });
    setUser(userWithRoleUUID);
  };

  const isAdmin = user?.role === ROLE_ADMIN_UUID;
  const isDutyOps = Array.isArray(dutyOps)
    ? dutyOps?.includes(user?.user)
    : false;

  const logout = () => {
    cookies.remove("user");
    setUser();
  };

  useEffect(() => {
    const user = cookies.get("user");
    if (user) setUser(user);
  }, []); // eslint-disable-line

  return (
    <UserContext.Provider
      value={{ user, handleSetUser, isAdmin, isDutyOps, logout }}
    >
      {children}
    </UserContext.Provider>
  );
}
