import { useState } from "react";
import { MdError, MdClose } from "react-icons/md";

function ErrorMessage(props) {
  const { errorCode, closeError } = props;
  const [closing, setClosing] = useState(false);

  function getErrorMessage() {
    switch (errorCode) {
      case "document-does-not-exist":
        return "Failed to retrieve the document.";
      default:
        return "Oops, something went wrong.";
    }
  }

  const handleError = () => {
    setClosing(true);
    setTimeout(() => {
      closeError();
      setClosing(false);
    }, 500);
  };

  return (
    <div
      className={`${
        errorCode ? `${closing ? "-right-full" : "right-10"}` : "-right-full"
      } absolute bottom-10 text-sm shadow-lg bg-white px-16 py-2 rounded-md transition-all ease-in-out duration-500`}
    >
      <MdError
        className="absolute left-3 top-1/2 -translate-y-1/2 p-2 bg-[#ef504e]/20 rounded-full"
        size={40}
        color="#ef504e"
      />
      <p className="w-[250px]">
        <strong className="text-md text-[#ef504e]">Error</strong>
        <br /> {getErrorMessage()}
      </p>
      <button
        type="button"
        className="absolute right-2 top-1/2 -translate-y-1/2 p-2"
        onClick={handleError}
      >
        <MdClose size={24} color="#b9b9b9" />
      </button>
    </div>
  );
}

export default ErrorMessage;
