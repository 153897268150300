import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FirestoreProvider } from "./context/FirestoreContext";
import { UserProvider } from "./context/UserContext";
import "./index.css";
import "./reset.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <FirestoreProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </FirestoreProvider>
  // </React.StrictMode>
);
