import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "../../Modal";
import { ImCheckmark } from "react-icons/im";
import { Task } from "./Task";
import {
  setDailyCheckTaskList,
  getDailyCheckTaskList,
} from "../../../services/firestore";

export function TaskListModal({ taskData, isOpen, handleClose }) {
  const [modalOpen, setModal] = useState(false);
  const [completedTasks, setCompletedTasks] = useState({});
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModal = (modal, task) => {
    setModal(modal);
    setModalContent(task);
  };
  const handleCloseModal = () => {
    setModal("");
  };

  const handleCompleteTask = (title) => {
    const data = {
      ...completedTasks,
      [title]: true,
    };
    setDailyCheckTaskList(data);
    setCompletedTasks(data);
    handleCloseModal();
  };

  useEffect(() => {
    const getCompletedTasks = async () => {
      if (isOpen === true) {
        const data = await getDailyCheckTaskList();
        setCompletedTasks(data);
      }
    };

    getCompletedTasks();
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        headerTitle="Dutyops"
        headerSubTitle={"Task List"}
        headerHelpText={"Tap an item to view contents and complete the task."}
        handleClose={handleClose}
      >
        <ModalBody mx="mx-10">
          <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-6 gap-y-4 md:grid-flow-col">
            {taskData?.map((data, index) => {
              const today = new Date();
              const weekday = today.getDay();
              const disabled = completedTasks[data?.title] === true;

              if (data.weekdays > 0) if (data.weekdays !== weekday) return null;

              return (
                <div
                  key={index}
                  className={`relative flex flex-col ${
                    data.weekdays > 0 ? "md:translate-y-8" : ""
                  }`}
                >
                  {data.weekdays > 0 && (
                    <span className="md:absolute left-0 -top-6 text-lg text-[#A7C1D5] font-semibold leading-none pb-1">
                      {data.weekdays === 1 && "Only mondays"}
                      {data.weekdays === 3 && "Only wednsdays"}
                    </span>
                  )}
                  <Button
                    key={index}
                    data={data}
                    openModal={handleOpenModal}
                    disabled={disabled}
                  />
                </div>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter options={"close"}>
          <div className="ml-auto">
            <button
              type="button"
              onClick={handleClose}
              className="py-[8px] px-[34px] ml-2 bg-[#6593B5] rounded-lg text-[#E9F0F4]"
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Task
        data={modalContent}
        isOpen={modalOpen === "task"}
        handleComplete={handleCompleteTask}
        closeModal={handleCloseModal}
      />
    </>
  );
}

function Button({ data, openModal, disabled }) {
  return (
    <button
      type="button"
      onClick={() => openModal("task", data)}
      className={`relative self-center w-full md:w-3/4 py-[8px] px-[24px] ml-2 ${
        disabled ? "bg-slate-300" : "bg-[#6593B5]"
      } rounded-lg font-bold text-lg text-[#E9F0F4] md:h-[60px] lg:w-2/3`}
      disabled={disabled}
    >
      {disabled ? (
        <span className="absolute inset-0 flex items-center justify-center opacity-70">
          <ImCheckmark color="#4dac50" size={30} />
        </span>
      ) : (
        ""
      )}
      {data.title}
    </button>
  );
}
