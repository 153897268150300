import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { UserContext } from "../context/UserContext";
import { login, register } from "../services/firestore";

export default function Auth() {
  const [page, setPage] = useState("login");
  const { user, handleSetUser } = useContext(UserContext);
  if (user) return;
  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#F3F4F6" />
      </Helmet>
      {page === "login" ? (
        <Login setPage={() => setPage("register")} setUser={handleSetUser} />
      ) : (
        ""
      )}
      {page === "register" ? (
        <Register setPage={() => setPage("login")} setUser={handleSetUser} />
      ) : (
        ""
      )}
    </>
  );
}

const Login = ({ setPage, setUser }) => {
  const [pilotName, setPilotName] = useState("");
  const [pin, setPin] = useState("");

  const handleSetPin = (e) => {
    const pin = Number(e.currentTarget.value);
    if (pin.toString().length > 4) return;
    setPin(pin);
  };

  const submitLogin = (e, pilotName, pin) => {
    e.preventDefault();
    login(pilotName, pin)
      .then((user) => setUser(user))
      .catch((message) => {
        console.log(message);
      });
  };

  return (
    <div className="relative h-full flex flex-col items-center justify-center bg-gray-100">
      <img
        src="/icon.png"
        alt="logo"
        className="w-[120px] rounded-xl self-center mt-20"
      />
      <form className="flex-1 flex flex-col gap-2 w-3/4 max-w-[400px] mt-10">
        <span className="text-2xl font-bold text-center py-4">Login</span>
        <input
          type="text"
          placeholder="PILOT NAME"
          className="border-2 p-2 uppercase"
          minLength={3}
          maxLength={3}
          onChange={(e) => setPilotName(e.currentTarget.value.toUpperCase())}
          value={pilotName}
          required
        />
        <input
          type="number"
          placeholder="PIN *4 digits"
          className="border-2 p-2"
          min={1000}
          max={9999}
          onChange={(e) => handleSetPin(e)}
          value={pin}
          required
        />
        <button
          type="submit"
          onClick={(e) => submitLogin(e, pilotName, pin)}
          className="border-2 p-2 bg-[#236495] text-white font-bold"
        >
          Submit
        </button>
      </form>

      <span className="pb-safe md:pb-4">
        No account yet?
        <button
          onClick={setPage}
          type="button"
          className="font-bold py-1 px-2 rounded-lg"
        >
          Register
        </button>
      </span>
    </div>
  );
};

const Register = ({ setPage, setUser }) => {
  const [pilotName, setPilotName] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [registerCode, setRegisterCode] = useState("");

  const handleSetPin = (e) => {
    const input = e.currentTarget.name;
    const pin = Number(e.currentTarget.value);
    if (pin.toString().length > 4) return;
    if (input === "pin") setPin(pin);
    if (input === "confirm-pin") setConfirmPin(pin);
  };

  const submitRegister = (
    e,
    pilotName,
    email,
    pin,
    confirmPin,
    registerCode
  ) => {
    e.preventDefault();

    pin === confirmPin
      ? register(pilotName, email, pin, registerCode)
          .then((user) => setUser(user))
          .catch((message) => {
            console.log(message);
          })
      : console.warn("pin's dont match!");
  };

  return (
    <div className="relative h-full flex flex-col items-center justify-center bg-gray-100">
      <img
        src="/icon.png"
        alt="logo"
        className="w-[120px] rounded-xl self-center mt-20"
      />
      <form className="flex-1 flex flex-col gap-2 w-3/4 max-w-[400px] mt-10">
        <span className="text-2xl font-bold text-center py-4">Register</span>
        <input
          type="text"
          placeholder="PILOT NAME"
          className="border-2 p-2 uppercase"
          minLength={3}
          maxLength={3}
          onChange={(e) => setPilotName(e.currentTarget.value.toUpperCase())}
          value={pilotName}
          required
        />
        <input
          type="email"
          placeholder="EMAIL"
          className="border-2 p-2"
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
          required
        />
        <input
          name="pin"
          type="number"
          placeholder="PIN *4 digits"
          className="border-2 p-2"
          min={1000}
          max={9999}
          onChange={(e) => handleSetPin(e)}
          value={pin}
          required
        />
        <input
          name="confirm-pin"
          type="number"
          placeholder="Confirm PIN"
          className="border-2 p-2"
          min={1000}
          max={9999}
          onChange={(e) => handleSetPin(e)}
          value={confirmPin}
          required
        />
        <input
          type="text"
          placeholder="REGISTER CODE"
          className="border-2 p-2"
          onChange={(e) => setRegisterCode(e.currentTarget.value)}
          value={registerCode}
          required
        />
        <button
          type="submit"
          onClick={(e) =>
            submitRegister(e, pilotName, email, pin, confirmPin, registerCode)
          }
          className="border-2 p-2 bg-[#236495] text-white font-bold"
        >
          Submit
        </button>
      </form>
      <span className="pb-safe md:pb-4">
        Already have an account?
        <button
          onClick={setPage}
          type="button"
          className="font-bold py-1 px-2 rounded-lg"
        >
          Login
        </button>
      </span>
    </div>
  );
};
