import { useEffect, useState } from "react";
import {
  createDailyCheck,
  getDailyCheckId,
  getSortedCollection,
} from "../services/firestore";

export function useStaticData() {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleReloadData = async () => {
    const timestamp = Math.floor(Date.now() / 1000);

    if (getLocalStorage("latestRefresh") + 3600 >= timestamp) return null;
    setLoading(true);
    localStorage.removeItem("aircraft");
    localStorage.removeItem("documents");
    localStorage.removeItem("tasks");
    getData().then(() => setLoading(false));
    setLocalStorage("latestRefresh", timestamp);
    window.location.reload(false);
  };

  const getLocalStorage = (item) => {
    return JSON.parse(localStorage.getItem(item));
  };

  const setLocalStorage = (item, data) => {
    localStorage.setItem(item, JSON.stringify(data));
  };

  async function getData() {
    setTimeout(async () => {
      const docId = await getDailyCheckId();
      if (!docId) createDailyCheck();
    }, 5000);
    const aircraftData = await getSortedCollection("aircraft");
    const documentData = await getSortedCollection("documents");
    const taskData = await getSortedCollection("tasks");

    const localAircraftData = getLocalStorage("aircraft");
    const localDocumentData = getLocalStorage("documents");
    const localTaskData = getLocalStorage("tasks");

    if (!localAircraftData && aircraftData)
      setLocalStorage("aircraft", aircraftData);
    if (!localDocumentData && documentData)
      setLocalStorage("documents", documentData);
    if (!localTaskData && taskData) setLocalStorage("tasks", taskData);
  }

  useEffect(() => {
    if (initialized) {
      setLoading(false);
      return;
    }
    const initDataInterval = setInterval(() => {
      const localAircraftData = getLocalStorage("aircraft");
      const localDocumentData = getLocalStorage("documents");
      const localTaskData = getLocalStorage("tasks");

      if (
        !initialized ||
        !localAircraftData ||
        !localDocumentData ||
        !localTaskData
      ) {
        getData().then(() => {
          setLoading(false);
          setInitialized(true);
        });
      } else {
        setLoading(false);
        clearInterval(initDataInterval);
      }
    }, 1000);

    return () => clearInterval(initDataInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  return { loading, handleReloadData };
}
