import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  query,
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
  setDoc,
  where,
  Timestamp,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getDailyCheckId = async () => {
  const today = new Date().toDateString();
  const tomorrow = () => {
    const date = new Date(today);
    date.setDate(date.getDate() + 1);
    return date.toDateString();
  };

  const timestampToday = Timestamp.fromDate(new Date(today));
  const timestampTomorrow = Timestamp.fromDate(new Date(tomorrow()));

  const collRef = collection(db, 'dailyChecks');
  const q = query(
    collRef,
    where('createdAt', '>=', timestampToday),
    where('createdAt', '<=', timestampTomorrow)
  );

  let docId = null;
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    docId = doc.id;
  });

  if (!docId) return;

  return docId;
};
const setDailyCheckTimestamp = () => {
  getDailyCheckId().then((res) => {
    const docId = res;
    const docRef = doc(db, 'dailyChecks', docId);
    return updateDoc(docRef, {
      updatedAt: serverTimestamp(),
    });
  });
};
export const getSortedCollection = async (col) => {
  const colRef = collection(db, col);
  const o = orderBy('sortId', 'asc');
  const q = query(colRef, o);
  const getFirestoreData = await getDocs(q);
  const data = getFirestoreData.docs.map((doc) => doc.data());

  if (!data) return;
  return data;
};
export const getDocument = async (col, docId) => {
  const acftDocRef = doc(db, col, docId);
  const docSnap = await getDoc(acftDocRef);

  if (!docSnap.exists()) return;
  const data = docSnap.data();
  return data;
};
export const useGetDailyChecks = () => {
  const [data, setData] = useState(null);
  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const docId = await getDailyCheckId();
      if (!docId) return setTimeout(() => getData(), 1000);

      const colRef = collection(db, 'dailyChecks', docId, 'aircraft');
      const unsubscribe = onSnapshot(colRef, (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            callSign: doc.id,
            data: doc.data(),
          };
        });
        if (!data) return;
        setData(data);
        //setLoading(false);
      });

      return unsubscribe;
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};
export const createDailyCheck = async () => {
  const docId = await getDailyCheckId();
  if (docId) return;
  const colRef = collection(db, 'dailyChecks');
  return addDoc(colRef, {
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};
export const getDailyCheckAircraft = async (callSign) => {
  const docId = await getDailyCheckId();
  if (!docId) return;
  const checkDocRef = doc(db, 'dailyChecks', docId, 'aircraft', callSign);
  const docSnap = await getDoc(checkDocRef);

  if (!docSnap.exists())
    return setDailyCheckAircraft(callSign)
      .then(() => {
        return getDailyCheckAircraft(callSign);
      })
      .catch(() => {
        throw new Error('document-does-not-exist');
      });

  const data = docSnap.data();
  return data;
};
export const setDailyCheckAircraft = async (callSign, data) => {
  const emergEquip = data?.emergEquip;
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId, 'aircraft', callSign);
  setDailyCheckTimestamp(); // TODO integrate staging of queries
  return setDoc(docRef, {
    totalTime: {
      hours: data?.totalTime.hours ?? '',
      minutes: data?.totalTime.minutes ?? '',
    },
    dueHoursAWS: data?.dueHoursAWS ?? '',
    dueDateAWS: data?.dueDateAWS ?? '',
    dueDateAWC: data?.dueDateAWC ?? '',
    emergEquip: {
      firstAid: {
        expiryMonth: emergEquip?.firstAid?.expiryMonth ?? '',
        expiryYear: emergEquip?.firstAid?.expiryYear ?? '',
      },
      fireExt: {
        expiryMonth: emergEquip?.fireExt?.expiryMonth ?? '',
        expiryYear: emergEquip?.fireExt?.expiryYear ?? '',
      },
      lifeJackets: {
        expiryMonth: emergEquip?.lifeJackets?.expiryMonth ?? '',
        expiryYear: emergEquip?.lifeJackets?.expiryYear ?? '',
      },
      PLB: {
        expiryMonth: emergEquip?.PLB?.expiryMonth ?? '',
        expiryYear: emergEquip?.PLB?.expiryYear ?? '',
      },
    },
  });
};
export const useGetDailyDocuments = () => {
  const [data, setData] = useState(null);
  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const docId = await getDailyCheckId();
      if (!docId) return setTimeout(() => getData(), 1000);

      const colRef = collection(db, 'dailyChecks', docId, 'documents');
      const unsubscribe = onSnapshot(colRef, (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return {
            callSign: doc.id,
            data: doc.data(),
          };
        });
        if (!data) return;
        setData(data);
        //setLoading(false);
      });

      return unsubscribe;
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};
export const setDailyCheckDocument = async (callSign, data) => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId, 'documents', callSign);
  setDailyCheckTimestamp(); // TODO integrate staging of queries
  setDoc(docRef, {
    ...data,
  });
};
export const getDailyCheckSoloLog = async () => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId, 'soloLog', 'students');
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) return [];
  const data = docSnap.data();
  return data.data;
};
export const setDailyCheckSoloLog = async (data) => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId, 'soloLog', 'students');
  setDailyCheckTimestamp(); // TODO integrate staging of queries
  setDoc(docRef, {
    ...data,
  });
};
export const getDailyCheckTaskList = async () => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId, 'tasks', 'completed');
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) return {};
  const data = docSnap.data();
  return data;
};
export const setDailyCheckTaskList = async (data) => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId, 'tasks', 'completed');
  setDailyCheckTimestamp(); // TODO integrate staging of queries
  setDoc(docRef, {
    ...data,
  });
};
export const getDailyCheckCollection = async (col) => {
  const docId = await getDailyCheckId();
  if (!docId) return;
  const colRef = collection(db, 'dailyChecks', docId, col);
  const q = query(colRef);
  const getFirestoreData = await getDocs(q);
  const data = getFirestoreData.docs.map((doc) => {
    return { id: doc.id, data: doc.data() };
  });
  if (!data) return null;
  return data;
};
export const getDailyCheckDutyOps = async () => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) return [];
  const data = docSnap.data();
  return data.dutyOps;
};
export const setDailyCheckDutyOps = async (data) => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId);
  setDailyCheckTimestamp(); // TODO integrate staging of queries
  updateDoc(docRef, {
    dutyOps: data,
  });
};
export const getDailyCheckExtraTasks = async () => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) return [];
  const data = docSnap.data();
  return data.extraTasks;
};
export const setDailyCheckExtraTasks = async (data) => {
  const docId = await getDailyCheckId();
  if (!docId) return;

  const docRef = doc(db, 'dailyChecks', docId);
  setDailyCheckTimestamp(); // TODO integrate staging of queries
  updateDoc(docRef, {
    extraTasks: data,
  });
};
export async function uploadPDFToFirebase(pdfBlob) {
  try {
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, "0")}${String(today.getDate()).padStart(2, "0")}${String(today.getFullYear()).slice(2)}`;
    const fileName = `dutyops_${formattedDate}.pdf`;

    const storageRef = ref(getStorage(), `pdfs/${fileName}`);
    const snapshot = await uploadBytes(storageRef, pdfBlob);
    const downloadURL = await getDownloadURL(snapshot.ref);

    console.log('File uploaded and available at:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading PDF to Firebase:", error);
    throw error;
  }
}

/* Handle user login/register */
export const register = async (username, email, pin, registerCode) =>
  new Promise(async (resolve, reject) => {
    const REGISTER_CODE = 'DOCAPA2023';
    const defaultRole = 'student';
    const encryptSecret = '7a7354db-4c87-4330-ae44-d0353daef3f1';

    const encryptedPIN = CryptoJS.AES.encrypt(
      JSON.stringify(pin),
      encryptSecret
    ).toString();

    if (REGISTER_CODE !== registerCode) return reject('register code not valid');

    const colRef = collection(db, 'users');
    const getFirestoreData = await getDocs(colRef);
    const users = getFirestoreData.docs.map((doc) => doc.data());
    const userExists = users.some((user) => user.username === username);
    const emailExists = users.some((user) => user.email === email);

    if (userExists) return reject('username already exists');
    if (emailExists) return reject('email already exists');

    if (!userExists && !emailExists)
      try {
        addDoc(colRef, {
          username,
          email,
          pin: encryptedPIN,
          role: defaultRole,
        });
        resolve({ username, role: defaultRole });
      } catch {
        reject('an error occured while trying to log in, try again.');
      }
  });
export const login = (username, pin) =>
  new Promise(async (resolve, reject) => {
    const colRef = collection(db, 'users');
    const getFirestoreData = await getDocs(colRef);
    const encryptSecret = '7a7354db-4c87-4330-ae44-d0353daef3f1';

    const users = getFirestoreData.docs.map((doc) => doc.data());
    const user = users.find((user) => {
      if (user.username !== username) return null;
      const decryptedPIN = () => {
        const bytes = CryptoJS.AES.decrypt(user.pin, encryptSecret);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return data;
      };
      if (decryptedPIN() !== pin) return null;
      return user;
    });
    if (user) resolve({ username: user.username, role: user.role });
    reject('an error occured while trying to log in, try again.');
  });
export const forgotPin = async (email) =>
  new Promise(async (resolve, reject) => {
    const colRef = collection(db, 'users');
    const getFirestoreData = await getDocs(colRef);
    const users = getFirestoreData.docs.map((doc) => doc.data());
    const user = users.find((user) => {
      if (user.email !== email) return null;
      return user;
    });
    if (user) resolve({ username: user.username, role: user.role });
    reject('an error occured while trying to log in, try again.');
  });
