import { useEffect, useState } from "react";
import { BsFillTrashFill, BsPlusLg } from "react-icons/bs";
import {
  getDailyCheckDutyOps,
  getDailyCheckExtraTasks,
  setDailyCheckDutyOps,
  setDailyCheckExtraTasks,
} from "../../../services/firestore";
import { Modal, ModalBody, ModalFooter } from "../../Modal";

export function AdminModal({ isOpen, handleClose }) {
  return (
    <>
      {/* <ErrorMessage errorCode={error} closeError={() => setError()} /> */}
      <Modal
        isOpen={isOpen}
        headerTitle="DutyOps"
        headerSubTitle={"Admin Panel"}
        handleClose={handleClose}
      >
        <ModalBody mx="mx-4 md:mx-10">
          <TodaysDutyOps isOpen={isOpen} />
          <ExtraTasks isOpen={isOpen} />
        </ModalBody>
        <ModalFooter>
          <div className="ml-auto">
            <button
              type="button"
              onClick={() => handleClose()}
              className="py-[8px] px-[24px]  border-[1px] bg-[#6593B5] rounded-lg text-[#E9F0F4]"
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

const TodaysDutyOps = ({ isOpen }) => {
  const [pilotName, setPilotName] = useState("");
  const [dutyOps, setDutyOps] = useState([]);

  const handleUpdateDutyPilot = (pilot) => {
    if (pilot.length !== 3) return;
    if (dutyOps.find((name) => name === pilot)) return;
    const data = [...dutyOps, pilot];
    setDutyOps(data);
    setDailyCheckDutyOps(data);
    setPilotName("");
  };
  const handleDeleteDutyPilot = (pilot) => {
    if (pilot.length !== 3) return;
    const data = dutyOps.filter((name) => name !== pilot);
    setDutyOps(data);
    setDailyCheckDutyOps(data);
  };

  useEffect(() => {
    if (isOpen === true)
      getDailyCheckDutyOps().then((data) => {
        setDutyOps(data ?? []);
        setPilotName("");
      });
  }, [isOpen]);

  return (
    <div className="min-h-[200px] mb-4">
      <h3 className="leading-none text-lg font-semibold text-[#6593B5]">
        DutyOps
      </h3>
      <h3 className="mb-2 leading-none text-md font-semibold text-[#6593B5]/30">
        Today
      </h3>
      <div className="mx-4">
        <div className="flex justify-center mb-2">
          <input
            name="pilotName"
            type="text"
            placeholder="Pilot name..."
            className="pl-2 border-2 border-r-0 rounded-l-md"
            value={pilotName}
            onChange={(e) => setPilotName(e.target.value.toUpperCase())}
          />
          <button
            type="button"
            onClick={() => handleUpdateDutyPilot(pilotName)}
            className="min-w-fit flex items-center gap-2 p-2 rounded-r-md text-white font-bold bg-[#6593B5]"
          >
            Add <BsPlusLg size={14} />
          </button>
        </div>
        <div className="h-10 grid grid-cols-2 gap-y-2 gap-x-4">
          {dutyOps.map((pilot, index) => (
            <div
              key={index}
              className="w-full rounded-md flex items-center bg-[#E9F0F4]"
            >
              <span className="flex flex-1 justify-center text-[#6593B5] font-semibold">
                {pilot}
              </span>
              <button
                type="button"
                onClick={() => handleDeleteDutyPilot(pilot)}
                className="p-2 h-full rounded-r-md bg-[#FF6666]"
              >
                <BsFillTrashFill color="white" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ExtraTasks = ({ isOpen }) => {
  const [taskName, setTaskName] = useState("");
  const [extraTasks, setExtraTasks] = useState([]);

  const handleUpdateExtraTask = (task) => {
    if (extraTasks.find((taskName) => taskName === task)) return;
    const data = [...extraTasks, task];
    setExtraTasks(data);
    setDailyCheckExtraTasks(data);
    setTaskName("");
  };
  const handleDeleteExtraTask = (task) => {
    const data = extraTasks.filter((taskName) => taskName !== task);
    setExtraTasks(data);
    setDailyCheckExtraTasks(data);
  };

  useEffect(() => {
    if (isOpen === true)
      getDailyCheckExtraTasks().then((data) => {
        setExtraTasks(data ?? []);
        setTaskName("");
      });
  }, [isOpen]);
  return (
    <div className="flex-1 overflow-auto">
      <h3 className="leading-none text-lg font-semibold text-[#6593B5]">
        Extra tasks
      </h3>
      <h3 className="mb-2 leading-none text-md font-semibold text-[#6593B5]/30">
        Today
      </h3>
      <div className="mx-4">
        <div className="flex justify-center mb-2">
          <input
            name="task"
            type="text"
            placeholder="Task..."
            className="pl-2 border-2 border-r-0 rounded-l-md"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
          <button
            type="button"
            onClick={() => handleUpdateExtraTask(taskName)}
            className="flex items-center gap-2 p-2 rounded-r-md text-white font-bold bg-[#6593B5]"
          >
            Add <BsPlusLg size={14} />
          </button>
        </div>
        <div className="grid grid-cols-1 gap-y-2 gap-x-4 p-2 max-h-full overflow-auto">
          {extraTasks.map((task, index) => (
            <div
              key={index}
              className="w-full rounded-md h-14 flex items-center bg-[#E9F0F4]"
            >
              <span className="flex flex-1 px-2 justify-center text-[#6593B5] font-semibold">
                {task}
              </span>
              <button
                type="button"
                onClick={() => handleDeleteExtraTask(task)}
                className="p-4 h-full flex items-center justify-center rounded-r-md bg-[#FF6666]"
              >
                <BsFillTrashFill color="white" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
