import { saveToPDF } from './saveToPDF';
import { uploadPDFToFirebase } from '../services/firestore';
import { sendEmailWithBackend } from '../services/sendgrid';

export async function handleGenerateAndSendPDF(
  acftData,
  docData,
  dailyDocuments,
  dailyAircraft,
  dutyOps
) {
  try {
    // Generate the PDF as a Blob
    const pdfBlob = await saveToPDF(
      acftData,
      docData,
      dailyDocuments,
      dailyAircraft,
      dutyOps
    );

    // Upload the PDF to Firebase
    const pdfUrl = await uploadPDFToFirebase(pdfBlob);

    const recipients = [
      // 'alex2beaver@gmail.com',
      'booking@centerair.dk',
      'mog@centerair.dk',
    ];

    // Send email with the PDF URL via SendGrid
    await sendEmailWithBackend(pdfUrl, recipients);
  } catch (error) {
    console.error('Error generating, uploading, or sending PDF:', error);
    throw new Error('Error generating, uploading, or sending PDF:', error);
  }
}
