import { useContext, useState } from "react";
import { EmergEquipDropdown } from "./EmergEquipDropdown";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { AiOutlineTablet } from "react-icons/ai";

import { Loader } from "../../Loader";
import { formatDate } from "../../../utils/formatDate";
import { FirestoreContext } from "../../../context/FirestoreContext";

export function AircraftTable({ acftData, handleOpenModal }) {
  const { dailyAircraft: tableData } = useContext(FirestoreContext);
  const loadingArray = Array.apply(null, { length: 8 });

  return (
    <div className="overflow-auto w-full pb-safe">
      <div className="table w-full text-sm">
        <div className="table-header-group z-10 sticky top-0 font-bold text-[#A7C1D5] bg-[#194668] leading-none">
          <div className="table-row h-[50px]">
            <div className="table-cell pl-5 align-middle text-left">
              REG.
              <div className="text-xs font-normal">(OY-XXX)</div>
            </div>
            <div className="table-cell md:hidden pl-5 align-middle text-left">
              TIME/DATES
              <div className="text-xs font-normal">Misc.</div>
            </div>
            <div className="hidden md:table-cell pl-5 align-middle text-left">
              TOTAL TIME
              <div className="text-xs font-normal">Airborne</div>
            </div>
            <div className="hidden md:table-cell pl-5 align-middle text-left">
              DUE HRS/DATE
              <div className="text-xs font-normal">Airworthiness stat.</div>
            </div>
            <div className="hidden md:table-cell pl-5 align-middle text-left">
              DUE DATE
              <div className="text-xs font-normal">Airworthiness cert.</div>
            </div>
            <div className="table-cell pl-5 align-middle text-left">
              EMERG. EQUIP.
              <div className="text-xs font-normal">Expiration dates</div>
            </div>
            <div className="table-cell pl-5 align-middle text-left"></div>
          </div>
        </div>
        <div className="relative table-row-group text-[#7BA2BF]">
          {acftData?.map((data, index) => {
            if (data.active === false) return null;

            let dropUp = false;
            if (acftData?.length - 5 <= index) dropUp = true;
            const checkData = tableData?.find(
              (elem) => elem.callSign === data.callSign
            );

            return (
              <Row
                key={index}
                data={checkData?.data}
                callSign={data?.callSign}
                shortName={data?.shortName}
                hasPLB={data?.hasPLB}
                dropUp={dropUp}
                handleOpenModal={(reg) => handleOpenModal(reg)}
              />
            );
          }) ||
            loadingArray.map((data, index) => {
              return <Row key={index} />;
            })}
          {!acftData && <Loader color="black" />}
        </div>
      </div>
    </div>
  );
}

function Row({ data, callSign, shortName, hasPLB, dropUp, handleOpenModal }) {
  const [openDropdown, setOpenDropdown] = useState(false);

  const toggleDropdown = (action) => {
    if (action === "close") {
      setOpenDropdown(false);
      return null;
    }
    setOpenDropdown((prev) => !prev);
  };

  const handleShowHourMinute = (hours, minutes, fallbackValue) => {
    if (hours && minutes) return `${hours}:${minutes}`;
    if (hours) return `${hours}:00`;
    if (minutes) return `0000:${minutes}`;

    return fallbackValue;
  };

  return (
    <div className="table-row h-[50px] px-4 even:bg-[#E9F0F4]">
      <div className="table-cell pl-5 align-middle leading-none">
        {callSign || "OY-XXX"}
        <span className="text-xs text-[#A7C1D5] block">{shortName || ""}</span>
      </div>
      <div className="table-cell md:hidden pl-5 align-middle text-left">
        <button
          type="button"
          className="p-1.5 rounded-md bg-[#7BA2BF] text-white disabled:bg-gray-400 flex items-center gap-1"
          disabled
        >
          View on <AiOutlineTablet />
        </button>
      </div>
      <div className={`hidden md:table-cell pl-5 align-middle`}>
        {handleShowHourMinute(
          data?.totalTime.hours,
          data?.totalTime.minutes,
          "-"
        )}
      </div>
      <div className="hidden md:table-cell pl-5 align-middle h-[50px] gap-3 leading-none">
        <div className="flex gap-3">
          <div className="flex flex-col justify-center">
            {data?.dueHoursAWS ? (
              <>
                {data?.dueHoursAWS}
                <span className="text-xs text-[#A7C1D5]">hours</span>
              </>
            ) : (
              "-"
            )}
          </div>
          <div className="flex flex-col justify-center">
            {data?.dueDateAWS ? (
              <>
                {formatDate(data?.dueDateAWS)}
                <span className="text-xs text-[#A7C1D5]">date</span>
              </>
            ) : (
              <span className="ml-6">-</span>
            )}
          </div>
        </div>
      </div>
      <div className="hidden md:table-cell pl-5 align-middle">
        {data?.dueDateAWC ? formatDate(data?.dueDateAWC) : "-"}
      </div>
      <div className="table-cell pl-5 align-middle relative">
        {!openDropdown ? (
          <button
            type="button"
            onClick={toggleDropdown}
            className="flex items-center gap-2"
          >
            <span className="underline decoration-2">VIEW MORE</span>
            {openDropdown ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        ) : (
          <div className="flex items-center gap-2">
            <span className="underline decoration-2">VIEW MORE</span>
            {openDropdown ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        )}
        <EmergEquipDropdown
          data={data?.emergEquip}
          hasPLB={hasPLB}
          callSign={callSign}
          dropUp={dropUp}
          isOpen={openDropdown}
          handleClose={() => toggleDropdown("close")}
        />
      </div>
      <div className="table-cell px-4 align-middle">
        <button
          type="button"
          onClick={() => handleOpenModal(callSign)}
          className="p-1.5 rounded-md bg-[#7BA2BF]"
        >
          <BiEdit color="white" size={20} />
        </button>
      </div>
    </div>
  );
}
