import { useEffect } from "react";
import { useContext } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import { FirestoreContext } from "../../../context/FirestoreContext";
import { setDailyCheckDocument } from "../../../services/firestore";

import { Loader } from "../../Loader";

export function DocumentsTable({ acftData, docData }) {
  const { dailyDocuments: tableData } = useContext(FirestoreContext);

  useEffect(() => {
    function isMonday(date = new Date()) {
      return date.getDay() === 1;
    }
    if (isMonday())
      docData?.push({
        item: "Copy of aircraft logbook",
        sortId: 11,
        type: "Aircraft document",
      });
  }, []); // eslint-disable-line
  return (
    <div className="relative h-[766px] max-h-[50%] md:max-h-[60%] flex text-sm overflow-y-auto">
      {!acftData || !docData ? <Loader color="black" /> : null}
      <div className="h-fit border-r-4 border-r-[#E9F0F4] bg-white">
        <div className="w-[185px] md:w-[210px] flex flex-col p-0">
          <h4 className="py-3 px-5 text-left font-bold text-[#A7C1D5] bg-[#194668]">
            ITEM
          </h4>
          <div className="w-full divide-y divide-[#E9F0F4]-200 font-semibold">
            {docData?.map((data, index) => (
              <div key={index}>
                <h5 className="my-2 px-5 text-[#6593B5] truncate">
                  {data.item}
                </h5>
              </div>
            ))}
            <div>
              <h5 className="my-3 px-5 font-bold text-[#194668]">
                Lock selection
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="h-fit w-full flex flex-col text-center overflow-x-auto">
        <div className="flex items-center">
          {acftData?.map((data, index) => {
            if (data.active === false) return null;
            return (
              <div
                key={index}
                className="min-w-[75px] flex-1 self-stretch py-3 px-2 font-bold text-[#A7C1D5] bg-[#194668]"
              >
                {data.callSign}
              </div>
            );
          })}
        </div>
        <div className="h-full w-full flex">
          {acftData?.map((data, index) => {
            if (data.active === false) return null;

            const checkboxData = tableData?.find((item) => {
              if (item.callSign === data.callSign) return item;
              return null;
            });

            const onChange = (e) => {
              const key = e.currentTarget.name;
              let value = e.currentTarget.checked;
              if (key === "disabled") value = !checkboxData?.data?.disabled;

              const newData = {
                ...checkboxData?.data,
                [key]: value,
              };

              setDailyCheckDocument(data.callSign, newData);
            };

            return (
              <div
                key={index}
                className="min-w-[75px] flex-1 divide-y divide-[#E9F0F4]-200 bg-white"
              >
                {docData?.map((data, index) => (
                  <CheckBox
                    key={index}
                    doc={data.item}
                    checked={checkboxData?.data[data.item]}
                    disabled={checkboxData?.data?.disabled}
                    onChange={onChange}
                  />
                ))}
                <div className="flex justify-center">
                  <button
                    name="disabled"
                    type="button"
                    onClick={(e) => onChange(e)}
                    className={`h-[30px] w-[30px] mt-2 mb-0.5 py-1 flex items-center justify-center rounded-md ${
                      checkboxData?.data?.disabled
                        ? "bg-[#6593B5]"
                        : "bg-white border border-[#6593B5]"
                    } `}
                  >
                    {checkboxData?.data?.disabled ? (
                      <FaLock color="#FFFFFF" />
                    ) : (
                      <FaUnlock color="#6593B5" />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const CheckBox = ({ doc, checked, disabled, onChange }) => {
  return (
    <div className="relative flex justify-center items-center py-1.5">
      <input
        name={doc}
        type="checkbox"
        className="opacity-0 absolute h-6 w-6"
        disabled={disabled}
        onChange={(e) => onChange(e)}
        checked={checked || ""}
      />
      <div className="bg-white border-2 rounded-sm border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center focus-within:border-[#6593b5]">
        <svg
          className="fill-current hidden w-3 h-3 text-[#6593b5] pointer-events-none"
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#FFFFFF" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};
