import { useEffect, useRef } from "react";

export function EmergEquipDropdown({
  data,
  callSign,
  hasPLB,
  isOpen,
  handleClose,
  dropUp,
}) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const emergEquipData = (equip) => {
    const equipName = data?.[equip];
    const expiryMonth = equipName?.expiryMonth;
    const expiryYear = equipName?.expiryYear;

    if (expiryMonth && expiryYear) return `${expiryMonth} ${expiryYear}`;

    return "No data";
  };

  return (
    <div
      ref={dropdownRef}
      className={`${!isOpen ? "hidden" : ""} ${
        dropUp ? "bottom-10" : "top-10"
      } z-[1] absolute w-44 -left-6 p-4 rounded-md shadow-lg bg-white leading-none space-y-2`}
    >
      <h3 className="font-bold text-right text-xs text-[#194668]">
        {callSign ?? "OY-XXX"}
      </h3>
      <div>
        <h4 className="text-md font-bold">First Aid Kit</h4>
        <h5 className="text-xs text-[#A7C1D5] font-semibold">
          {emergEquipData("firstAid")}
        </h5>
      </div>
      <div>
        <h4 className="text-md font-bold">Fire Extinguisher</h4>
        <h5 className="text-xs text-[#A7C1D5] font-semibold">
          {emergEquipData("fireExt")}
        </h5>
      </div>
      <div>
        <h4 className="text-md font-bold">Life Jackets</h4>
        <h5 className="text-xs text-[#A7C1D5] font-semibold">
          {emergEquipData("lifeJackets")}
        </h5>
      </div>
      {hasPLB ? (
        <div>
          <h4 className="text-md font-bold">PLB</h4>
          <h5 className="text-xs text-[#A7C1D5] font-semibold">
            {emergEquipData("PLB")}
          </h5>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
