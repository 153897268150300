import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "../../Modal";

import { IoMdTime } from "react-icons/io";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import {
  getDailyCheckAircraft,
  setDailyCheckAircraft,
} from "../../../services/firestore";
import { Loader } from "../../Loader";
import ErrorMessage from "../../ErrorMessage";

export function EditAircraftModal({ callSign, isOpen, handleClose }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [savingState, setSavingState] = useState(false);

  useEffect(() => {
    if (callSign && !data) {
      getDailyCheckAircraft(callSign)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          setError(error.message);
          handleClose();
        });
    } else if (!callSign && data) {
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSign, data]);

  function handleSubmit(callSign, data) {
    setSavingState(true);
    // update firestore or create new document
    setDailyCheckAircraft(callSign, data).then((res) => {
      setSavingState("complete");
      setTimeout(() => {
        setSavingState(false);
        handleClose();
      }, 2000);
    });
  }

  function confirmClose() {
    let result = window.confirm(
      "Are you sure you want to close? Unsaved changes will be lost!"
    );
    if (result) {
      handleClose();
    }
  }

  return (
    <>
      <ErrorMessage errorCode={error} closeError={() => setError()} />
      <Modal
        isOpen={isOpen}
        headerTitle="Editing"
        headerSubTitle={callSign}
        handleClose={confirmClose}
        overflowHidden={(savingState === true) | !data}
      >
        {(savingState === true) | !data ? <Loader color="black" /> : ""}
        {savingState === "complete" && (
          <div className="z-10 absolute top-0 bottom-0 left-0 right-0 backdrop-blur-sm">
            <div className="h-full w-full flex flex-col items-center justify-center">
              <img
                src="/green-check.svg"
                className="h-10 w-10"
                alt="green checkmark"
              />
            </div>
          </div>
        )}
        <ModalBody mx="mx-10">
          <Form data={data} setData={setData} />
        </ModalBody>
        <ModalFooter>
          <div className="ml-auto">
            <button
              type="button"
              onClick={() => confirmClose()}
              className="py-[8px] px-[24px]  border-[1px] border-[#6593B5] rounded-lg text-[#7BA2BF]"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => handleSubmit(callSign, data)}
              className="py-[8px] px-[24px] ml-2 bg-[#6593B5] rounded-lg text-[#E9F0F4]"
            >
              Save Changes
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

function Form({ data, setData }) {
  const handleExpiryChange = (name, value) => {
    let field = "expiryYear";
    if (isNaN(value)) field = "expiryMonth";

    setData({
      ...data,
      emergEquip: {
        ...data.emergEquip,
        [name]: {
          ...data.emergEquip?.[name],
          [field]: value,
        },
      },
    });
  };

  return (
    <form>
      <div>
        <label className="flex flex-col text-[#6593B5] font-semibold">
          TOTAL TIME
          <span className="leading-none text-sm text-[#A7C1D5] font-normal">
            Airborne (Red logbook)
          </span>
        </label>
        <div className="relative h-[50px] flex items-center px-2 mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
          <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
            Hours
          </span>
          <input
            type="number"
            pattern="[0-6][0-9]"
            className="w-full pl-2"
            value={data?.totalTime?.hours || 0}
            onChange={(e) =>
              setData({
                ...data,
                totalTime: {
                  ...data.totalTime,
                  hours: e.target.value.replace(/^0+(?!\.|$)/, "") || 0,
                },
              })
            }
          />
          :
          <span className="absolute top-[-10px] right-24 md:right-52 px-0.5 font-semibold leading-none bg-white">
            Minutes
          </span>
          <input
            type="number"
            max="60"
            pattern="[0-6][0-9]"
            className="w-full pl-2"
            value={data?.totalTime?.minutes || 0}
            onChange={(e) => {
              let minutes = e.target.value.replace(/^0+(?!\.|$)/, "");
              if (parseInt(minutes) > 59) return;
              if ((parseInt(minutes) > 0) & (parseInt(minutes) < 10))
                minutes = "0" + minutes;
              setData({
                ...data,
                totalTime: {
                  ...data.totalTime,
                  minutes: minutes || 0,
                },
              });
            }}
          />
          <IoMdTime size={24} color="#646464" className="w-14 ml-2" />
        </div>
      </div>
      <div className="mt-6">
        <label className="flex flex-col text-[#6593B5] font-semibold">
          DUE HOURS & DATE
          <span className="leading-none text-sm text-[#A7C1D5] font-normal">
            Airworthiness statement
          </span>
        </label>
        <div className="flex gap-2 justify-between">
          <div className="relative h-[50px] flex-1 flex items-center px-2 mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
            <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
              Hours
            </span>
            <input
              type="number"
              pattern="[0-6][0-9]"
              className="w-full pl-2"
              value={data?.dueHoursAWS || 0}
              onChange={(e) =>
                setData({
                  ...data,
                  dueHoursAWS: e.target.value.replace(/^0+(?!\.|$)/, "") || 0,
                })
              }
            />
            <IoMdTime size={24} color="#646464" className="ml-2" />
          </div>
          <div className="relative h-[50px] flex-1 flex items-center px-2 mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
            <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
              Date
            </span>
            <input
              type="date"
              className="w-full"
              value={data?.dueDateAWS || ""}
              onChange={(e) =>
                setData({
                  ...data,
                  dueDateAWS: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <label className="flex flex-col text-[#6593B5] font-semibold">
          DUE DATE
          <span className="leading-none text-sm text-[#A7C1D5] font-normal">
            Airworthiness certificate
          </span>
        </label>
        <div className="relative h-[50px] flex items-center px-2 mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
          <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
            Date
          </span>
          <input
            type="date"
            className="w-full"
            value={data?.dueDateAWC || ""}
            onChange={(e) =>
              setData({
                ...data,
                dueDateAWC: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="mt-6">
        <label className="flex flex-col text-[#6593B5] font-semibold">
          EMERGENCY EQUIPMENT
          <span className="leading-none text-sm text-[#A7C1D5] font-normal">
            Expiration dates
          </span>
        </label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
          <div className="relative h-[50px] flex items-center mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
            <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
              First Aid Kit
            </span>
            <DatePicker
              name="firstAid"
              selctMonth={data?.emergEquip?.firstAid?.expiryMonth}
              selctYear={data?.emergEquip?.firstAid?.expiryYear}
              setData={handleExpiryChange}
            />
          </div>
          <div className="relative h-[50px] flex items-center mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
            <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
              Fire Extinguisher
            </span>
            <DatePicker
              name="fireExt"
              selctMonth={data?.emergEquip?.fireExt?.expiryMonth}
              selctYear={data?.emergEquip?.fireExt?.expiryYear}
              setData={handleExpiryChange}
            />
          </div>
          <div className="relative h-[50px] flex items-center mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
            <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
              Life Jackets
            </span>
            <DatePicker
              name="lifeJackets"
              selctMonth={data?.emergEquip?.lifeJackets?.expiryMonth}
              selctYear={data?.emergEquip?.lifeJackets?.expiryYear}
              setData={handleExpiryChange}
            />
          </div>
          <div className="relative h-[50px] flex items-center mt-5 border-[2px] border-[#B7B7B7] rounded-lg text-[#646464] last">
            <span className="absolute top-[-10px] left-2 px-0.5 font-semibold leading-none bg-white">
              PLB
            </span>
            <DatePicker
              name="PLB"
              selctMonth={data?.emergEquip?.PLB?.expiryMonth}
              selctYear={data?.emergEquip?.PLB?.expiryYear}
              setData={handleExpiryChange}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

function DatePicker({ name, selctMonth, selctYear, setData }) {
  const [monthOpen, setMonthOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

  return (
    <div className="w-full flex divide-x divide-[#E9F0F4]-600">
      <div className="relative flex flex-1 items-center">
        <button
          onClick={() => setMonthOpen((prev) => !prev)}
          type="button"
          className="w-full pl-2 flex justify-between items-center"
        >
          <span>{selctMonth || "Month"}</span>
          {!monthOpen ? (
            <RiArrowDownSFill size={34} color="#6593B5" />
          ) : (
            <RiArrowUpSFill size={34} color="#6593B5" className="mt-1" />
          )}
        </button>
        <div
          className={`${
            !monthOpen ? "hidden" : "z-10"
          } absolute bottom-full right-1/2 -translate-y-2 translate-x-1/2 w-full max-h-[150px] overflow-auto rounded-md shadow-lg bg-white`}
        >
          <ul className="flex flex-col">
            {months.map((month, index) => (
              <li
                className={`${
                  month === selctMonth ? "text-gray-500 bg-gray-200" : ""
                } px-2 py-2 align-middle`}
                key={index}
                onClick={() => {
                  if (selctMonth !== month) {
                    setData(name, month);
                    setMonthOpen(false);
                  }
                }}
              >
                {month}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="relative flex flex-1 items-center">
        <button
          onClick={() => setYearOpen((prev) => !prev)}
          type="button"
          className="w-full pl-2 flex justify-between items-center"
        >
          <span>{selctYear || "Year"}</span>
          {!yearOpen ? (
            <RiArrowDownSFill size={34} color="#6593B5" />
          ) : (
            <RiArrowUpSFill size={34} color="#6593B5" className="mt-1" />
          )}
        </button>
        <div
          className={`${
            !yearOpen ? "hidden" : "z-10"
          } absolute bottom-full right-1/2 -translate-y-2 translate-x-1/2 w-full max-h-[150px] overflow-auto rounded-md shadow-lg bg-white`}
        >
          <ul className="flex flex-col">
            {years.map((year, index) => (
              <li
                className={`${
                  year === selctYear ? "text-gray-500 bg-gray-200" : ""
                } px-2 py-2`}
                key={index}
                onClick={() => {
                  if (selctYear !== year) {
                    setData(name, year);
                    setYearOpen(false);
                  }
                }}
              >
                {year}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
