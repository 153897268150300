import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatDate } from './formatDate';

export async function saveToPDF(
  acftData,
  docData,
  dailyDocuments,
  dailyAircraft,
  dutyOps
) {
  const doc = new jsPDF({ orientation: 'landscape' });

  const activeAircraft = acftData
    .filter((aircraft) => aircraft.active !== false)
    .map((aircraft) => aircraft.callSign);

  const documentItems = docData.map((doc) => doc.item);
  const logo = await loadImage('/logo.png');
  const today = getCurrentDate();

  addHeader(doc, logo, today, dutyOps);
  addDocumentTable(doc, activeAircraft, documentItems, dailyDocuments);
  doc.addPage();
  addAircraftTable(doc, activeAircraft, dailyAircraft);

  return new Promise((resolve) => {
    const pdfBlob = doc.output('blob');
    resolve(pdfBlob);
  });
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = '';
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
}

function getCurrentDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

function addHeader(doc, logo, today, dutyOps) {
  doc.addImage(logo, 247, 5, 33, 15);
  const dutyOpsText = dutyOps
    ? `DutyOps: ${dutyOps[0] || ''}${dutyOps[1] ? ` & ${dutyOps[1]}` : ''}`
    : 'DutyOps: None';
  doc.text(dutyOpsText, 15, 17);
  doc.text(`Date: ${today}`, 130, 17);
  doc.text('Documents', 15, 30);
}

function addDocumentTable(doc, activeAircraft, documentItems, dailyDocuments) {
  const tableData = documentItems.map((item) => {
    return [
      item,
      ...activeAircraft.map((acft) =>
        dailyDocuments?.find((doc) => doc.callSign === acft)?.data[item] ? 'X' : '-'
      ),
    ];
  });

  const tableHead = [
    { content: 'Document', styles: { halign: 'left', fontSize: 10 } },
    ...activeAircraft.map((acft) => ({ content: acft })),
  ];

  autoTable(doc, {
    startY: 35,
    styles: { halign: 'center' },
    headStyles: { fontSize: 8, valign: 'middle' },
    bodyStyles: { minCellHeight: 10, valign: 'middle' },
    columnStyles: { 0: { halign: 'left', cellWidth: 'auto', border: { right: 4 } } },
    head: [tableHead],
    body: tableData,
  });
}

function addAircraftTable(doc, activeAircraft, dailyAircraft) {
  const tableData = activeAircraft.map((acft) => {
    const data = dailyAircraft?.find((doc) => doc.callSign === acft)?.data;
    return [
      acft,
      data ? `${data.totalTime.hours}:${data.totalTime.minutes}` : '',
      data ? `${data.dueHoursAWS} / ${formatDate(data.dueDateAWS)}` : '',
      data ? formatDate(data.dueDateAWC) : '',
      data
        ? `${data.emergEquip.firstAid.expiryMonth} / ${data.emergEquip.firstAid.expiryYear}`
        : '',
      data
        ? `${data.emergEquip.fireExt.expiryMonth} / ${data.emergEquip.fireExt.expiryYear}`
        : '',
      data
        ? `${data.emergEquip.lifeJackets.expiryMonth} / ${data.emergEquip.lifeJackets.expiryYear}`
        : '',
      data
        ? `${data.emergEquip.PLB.expiryMonth} / ${data.emergEquip.PLB.expiryYear}`
        : '',
    ];
  });

  const tableHead = [
    [
      { content: 'REG.', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'Total time', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'Due hrs/date', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'Due date', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'First aid', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'Fire ext.', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'Life jackets', styles: { cellPadding: { left: 1.5, top: 2 } } },
      { content: 'PLB', styles: { cellPadding: { left: 1.5, top: 2 } } },
    ],
    [
      {
        content: '(OY-XXX)',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Airborne',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Airworthiness statement',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Airworthiness certificate',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Month / Year',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Month / Year',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Month / Year',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
      {
        content: 'Month / Year',
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          cellPadding: { left: 1.5, bottom: 2 },
        },
      },
    ],
  ];

  doc.text('Aircraft', 15, 15);

  autoTable(doc, {
    startY: 20,
    head: tableHead,
    body: tableData,
    bodyStyles: { minCellHeight: 10, valign: 'middle' },
  });
}
