import { createContext, useEffect, useState } from "react";
import {
  getDailyCheckId,
  getDocument,
  useGetDailyChecks,
  useGetDailyDocuments,
} from "../services/firestore";

export const FirestoreContext = createContext();

const getLocalStorage = (item) => {
  return JSON.parse(localStorage.getItem(item));
};

export function FirestoreProvider({ children }) {
  const [aircraftData, setAircraftData] = useState(
    getLocalStorage("aircraft") || null
  );
  const [documentData, setDocumentData] = useState(
    getLocalStorage("documents") || null
  );
  const [taskData, setTaskData] = useState(getLocalStorage("tasks") || null);
  const [dutyOps, setDutyOps] = useState();
  const dailyCheckAircraft = useGetDailyChecks();
  const dailyCheckDocuments = useGetDailyDocuments();

  useEffect(() => {
    !dutyOps &&
      setDutyOps(async () => {
        const checkId = await getDailyCheckId();
        getDocument("dailyChecks", checkId).then((data) => {
          setDutyOps(data.dutyOps);
        });
      });

    const initDataInterval = setInterval(async () => {
      if (aircraftData && documentData && taskData)
        return () => clearInterval(initDataInterval);
      !aircraftData && setAircraftData(getLocalStorage("aircraft"));
      !documentData && setDocumentData(getLocalStorage("documents"));
      !taskData && setTaskData(getLocalStorage("tasks"));
    }, 1000);

    return () => clearInterval(initDataInterval);
  }, [aircraftData, documentData, taskData]); // eslint-disable-line

  const value = {
    aircraftData,
    documentData,
    taskData,
    dailyAircraft: dailyCheckAircraft,
    dailyDocuments: dailyCheckDocuments,
    dutyOps,
  };
  return (
    <FirestoreContext.Provider value={value}>
      {children}
    </FirestoreContext.Provider>
  );
}
