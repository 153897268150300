import React from "react";
import { AiOutlineClose } from "react-icons/ai";

function Modal({
  isOpen,
  headerTitle,
  headerSubTitle,
  headerHelpText,
  handleClose,
  width,
  overflowHidden,
  children,
}) {
  return (
    <>
      <div
        className={`${
          !isOpen ? "bg-black/0 pointer-events-none" : "bg-black/50"
        } z-10 absolute inset-0 transition-all ease-in-out duration-500 flex justify-center items-center`}
      >
        <div
          className={`${
            isOpen ? "scale-100" : "scale-0"
          } flex flex-col relative min-h-full max-h-full md:min-h-[50%] md:max-h-[80%] w-full ${
            width || "md:w-4/5"
          } bg-white md:rounded-3xl transition-all ease-in-out duration-300 ${
            overflowHidden ? "overflow-hidden" : "overflow-auto"
          }`}
        >
          <Header
            title={headerTitle}
            subtitle={headerSubTitle}
            helpText={headerHelpText}
            handleClose={handleClose}
          />
          {children}
        </div>
      </div>
    </>
  );
}

function Header({ handleClose, title, subtitle, helpText }) {
  return (
    <div className="w-full flex p-5">
      <div className="flex-1">
        <h3 className="text-[#6593B5]">{title}</h3>
        <h4 className="text-[#194668] font-semibold text-xl mt-[-8px]">
          {subtitle}
        </h4>
        <h5 className="mt-1 text-[#6593B5]">{helpText}</h5>
      </div>
      <button
        type="button"
        onClick={handleClose}
        className="w-[36px] h-[36px] flex items-center justify-center rounded-full bg-[#E9F0F4]"
      >
        <AiOutlineClose color="#6593B5" size={20} />
      </button>
    </div>
  );
}
function ModalBody({ children, mx }) {
  return <div className={`flex-1 ${mx || ""}`}>{children}</div>;
}
function ModalFooter({ children }) {
  return (
    <div className="relative">
      <div className="w-full min-h-[65px] flex items-center py-4 md:py-0 px-5 mt-4 md:mt-8 bg-[#F4F4F7] md:rounded-b-3xl">
        {children}
      </div>
      <div className="pb-safe md:hidden w-full bg-[#F4F4F7]"></div>
    </div>
  );
}

export { Modal, ModalBody, ModalFooter };
