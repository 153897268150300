import { useState, useEffect } from "react";
import { BsFillTrashFill, BsPlusLg } from "react-icons/bs";
import {
  getDailyCheckSoloLog,
  setDailyCheckSoloLog,
} from "../../../services/firestore";

export function SoloLog({ isOpen }) {
  const [soloLog, setSoloLog] = useState([]);
  const [inputPilotName, setInputPilotName] = useState("");

  const handleUpdateSoloLog = (pilot) => {
    if (pilot.length !== 3) return;
    if (soloLog.find((name) => name === pilot)) return;
    const data = [...soloLog, pilot];
    setSoloLog(data);
    setDailyCheckSoloLog({ data: data });
    setInputPilotName("");
  };
  const handleDeleteSoloLog = (pilot) => {
    if (pilot.length !== 3) return;
    const data = soloLog.filter((name) => name !== pilot);
    setSoloLog(data);
    setDailyCheckSoloLog({ data: data });
  };

  useEffect(() => {
    if (isOpen === true)
      getDailyCheckSoloLog().then((data) => {
        setSoloLog(data ?? []);
        setInputPilotName("");
      });
  }, [isOpen]);

  return (
    <div className="w-full mt-2">
      <h3 className="mb-2 leading-none text-lg font-semibold text-[#6593B5]">
        Students
      </h3>
      <div className="flex justify-center mb-2">
        <input
          name="pilotName"
          type="text"
          placeholder="Enter name..."
          className="pl-2 border-2 border-r-0 rounded-l-md"
          value={inputPilotName ?? ""}
          onChange={(e) => setInputPilotName(e.target.value.toUpperCase())}
        />
        <button
          type="button"
          onClick={() => handleUpdateSoloLog(inputPilotName)}
          className="flex items-center gap-2 p-2 rounded-r-md text-white font-bold bg-[#6593B5]"
        >
          Add <BsPlusLg size={14} />
        </button>
      </div>
      <div className="grid grid-cols-2 gap-y-2 gap-x-6 p-2 max-h-[150px] overflow-auto">
        {soloLog.map((pilot, index) => {
          return (
            <div
              key={index}
              className="w-full rounded-md flex items-center bg-[#E9F0F4]"
            >
              <span className="flex flex-1 justify-center text-[#6593B5] font-semibold">
                {pilot}
              </span>
              <button
                type="button"
                onClick={() => handleDeleteSoloLog(pilot)}
                className="p-2 rounded-r-md bg-[#FF6666]"
              >
                <BsFillTrashFill color="white" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
