import { useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';

export function Header({ handleOpenModal }) {
  const [date] = useState(getDate(new Date()));
  const [time, setTime] = useState(getTime(new Date()));

  function getDate(today) {
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    return day + '/' + month + '/' + year;
  }

  function getTime(today) {
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();

    if (hours < 10) hours = '0' + today.getHours();
    if (minutes < 10) minutes = '0' + today.getMinutes();
    if (seconds < 10) seconds = '0' + today.getSeconds();

    return hours + ':' + minutes + ':' + seconds;
  }

  useEffect(() => {
    let newTime = setInterval(() => {
      setTime(getTime(new Date()));
    }, 1000);

    return () => clearInterval(newTime);
  }, []);

  return (
    <header className="h-[65px] flex items-center py-5 px-[3%]">
      <ul className="flex flex-1 flex-wrap items-center justify-between text-[#6593B5] font-bold text-md md:text-lg">
        <li className="flex order-last flex-[1_0_100%] text-sm justify-between leading-none md:hidden">
          <div>
            Date
            <span className="px-1 font-normal text-[#A7C1D5] ">{date}</span>
          </div>
          <div className="min-w-[75px]">
            Time
            <span className="px-1 font-normal text-[#A7C1D5]">{time}</span>
          </div>
          <div>
            Dutyops
            <span className="pl-1 font-normal text-[#A7C1D5]">Task App</span>
          </div>
        </li>
        <li className="flex-col leading-none hidden md:flex">
          Date
          <span className="font-normal text-[#A7C1D5] ">{date}</span>
        </li>
        <li className="flex-col leading-none min-w-[75px] hidden md:flex">
          Time
          <span className="font-normal text-[#A7C1D5]">{time}</span>
        </li>
        <li className="hidden md:flex flex-col leading-none">
          Dutyops
          <span className="font-normal text-[#A7C1D5]">Task App</span>
        </li>
        <li>
          <button
            className="flex items-center px-4 py-1 rounded-lg text-[#FFFFFF] bg-[#6593B5]"
            onClick={handleOpenModal}
          >
            Task List <BsThreeDots className="ml-4" size={24} color="#E9F0F4" />
          </button>
        </li>
        <li className="h-[40px] md:h-[60px]">
          <img className="h-full" src="/logo.webp" alt="Center air logo" />
        </li>
      </ul>
    </header>
  );
}
