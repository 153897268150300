import { DutyOps } from "./components/DutyOps";

function App() {
  return (
    <>
      <main className="relative h-full overflow-hidden lg:hidden">
        <DutyOps />
      </main>
      <LargeDevicePrompt />
    </>
  );
}

export default App;

const LargeDevicePrompt = () => {
  return (
    <div className="hidden lg:flex h-full justify-center items-center">
      <div className="w-[500px] h-[200px] flex flex-col bg-slate-100 rounded-lg">
        <div className="relative flex flex-1 items-center pl-14">
          <img
            className="h-20 scale-x-[-1] animate-[float_6s_ease-in-out_infinite]"
            src="/tablet-vector.png"
            alt="tablet device"
          />
          <span className="absolute top-8 left-16 px-2 py-1 bg-red-200 rounded-md text-gray-800 shadow-lg">
            Hi!
          </span>
          <span className="absolute bottom-10 right-14 px-2 py-1 bg-violet-300 rounded-md text-gray-800 shadow-lg">
            Please open this page on a <strong>tablet</strong> device!
          </span>
        </div>
        <div className="flex p-1 rounded-b-lg bg-slate-300">
          <span className="py-2 flex-1 rounded-b-md bg-white">
            <img
              src="/logo-transparent.png"
              alt="center air logo"
              className="h-12 mx-auto"
            />
          </span>
        </div>
      </div>
    </div>
  );
};
