import { useEffect, useState } from "react";
import { getDailyCheckExtraTasks } from "../../../services/firestore";
import { Modal, ModalBody, ModalFooter } from "../../Modal";
import { SoloLog } from "./SoloLog";

export function Task({ data, handleComplete, isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      headerTitle={"Task"}
      headerSubTitle={data?.title}
      handleClose={closeModal}
      width={"md:w-4/6"}
    >
      <ModalBody mx="mx-6">
        <h3 className="mb-2 leading-none text-lg font-semibold text-[#6593B5]">
          Description
        </h3>
        <p className="mx-2">{data?.description}</p>

        {data?.title.toLowerCase() === "check solo log" ? (
          <SoloLog isOpen={isOpen} />
        ) : data?.title.toLowerCase() === "extra tasks" ? (
          <ExtraTasks isOpen={isOpen} />
        ) : (
          ""
        )}
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <button
            type="button"
            onClick={closeModal}
            className="py-[8px] px-[24px]  border-[1px] border-[#6593B5] rounded-lg text-[#7BA2BF]"
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => handleComplete(data?.title)}
            className="py-[8px] px-[24px] ml-2 bg-[#6593B5] rounded-lg text-[#E9F0F4]"
          >
            Complete
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

const ExtraTasks = ({ isOpen }) => {
  const [extraTasks, setExtraTasks] = useState([]);

  useEffect(() => {
    if (isOpen === true)
      getDailyCheckExtraTasks().then((data) => {
        setExtraTasks(data ?? []);
      });
  }, [isOpen]);

  return (
    <div className="mt-4 grid grid-cols-1">
      <h3 className="leading-none text-lg font-semibold text-[#6593B5]">
        Extra tasks
      </h3>
      <h3 className="mb-2 leading-none text-md font-semibold text-[#6593B5]/30">
        Today
      </h3>
      <div className="mx-2">
        {extraTasks.length > 0 ? (
          extraTasks.map((task, index) => (
            <div
              key={index}
              className="w-full rounded-md h-14 flex items-center"
            >
              <span className="text-xl mr-2">{index + 1}.</span>
              <span className="flex flex-1 px-2 text-[#6593B5] font-semibold">
                {task}
              </span>
            </div>
          ))
        ) : (
          <h3>No extra tasks posted!</h3>
        )}
      </div>
    </div>
  );
};
