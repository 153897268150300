import axios from 'axios';

export async function sendEmailWithBackend(pdfUrl, recipientEmails) {
  try {
    const response = await axios.post('https://api.netcore.apnet.dk/send-email', {
      pdfUrl: pdfUrl,
      recipientEmails: recipientEmails, // Pass an array of recipient emails
      secret: '8f6cb986-cfb2-42d0-991c-c886e7b8db3f',
    });
    console.log(response.data);
  } catch (error) {
    console.error('Error sending email via backend:', error);
    throw new Error('Error sending email via backend:', error);
  }
}
