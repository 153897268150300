import React, { useContext, useState, useEffect } from 'react';
import { Header } from './Header';
import { AircraftTable, DocumentsTable } from './Tables';
import { Title } from './Title';
import { EditAircraftModal } from './Modals/EditAircraft';
import { TaskListModal } from './Modals/TaskList';
import { BiRefresh } from 'react-icons/bi';
import { useStaticData } from '../../hooks/useStaticData';
import { Loader } from '../Loader';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { handleGenerateAndSendPDF } from '../../utils/handlePDF';
import { FirestoreContext } from '../../context/FirestoreContext';
import Auth from '../Auth';
import { Helmet } from 'react-helmet';
import { UserContext } from '../../context/UserContext';
import { MdAdminPanelSettings, MdLock } from 'react-icons/md';
import { AdminModal } from './Modals/Admin';

export function DutyOps() {
  const { loading, handleReloadData } = useStaticData();

  const [modal, setModal] = useState('');
  const [callSign, setCallSign] = useState('');

  const { aircraftData, documentData, taskData, dutyOps } = useContext(FirestoreContext);

  const { user, isAdmin, isDutyOps, logout } = useContext(UserContext);
  const handleOpenModal = (modal, reg) => {
    reg && setCallSign(reg);
    setModal(modal);
  };
  const handleCloseModal = () => {
    setCallSign('');
    setModal('');
  };

  if (!user) return <Auth user={user} />;
  if (!isDutyOps && !isAdmin) return <NoAccess />;

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#FFFFFF" />
      </Helmet>
      <div className="relative h-full flex flex-col lg:max-w-[1200px] lg:mx-auto">
        {isAdmin ? (
          <AdminBtn handleOpenModal={() => handleOpenModal('adminPanel')} />
        ) : (
          ''
        )}
        <Logout logout={logout} />
        <ExportToPDF acftData={aircraftData} docData={documentData} dutyOps={dutyOps} />
        <RefreshBtn loading={loading} refresh={handleReloadData} />

        <Header handleOpenModal={() => handleOpenModal('taskList')} />
        <div className="h-[calc(100%-65px)] flex flex-col">
          <Title title="Documents" />
          <DocumentsTable docData={documentData} acftData={aircraftData} />

          <Title title="Aircraft" />
          <AircraftTable
            acftData={aircraftData}
            handleOpenModal={(reg) => handleOpenModal('editAircraft', reg)}
          />
        </div>
      </div>
      {isAdmin ? (
        <AdminModal isOpen={modal === 'adminPanel'} handleClose={handleCloseModal} />
      ) : (
        ''
      )}
      <EditAircraftModal
        callSign={callSign}
        isOpen={modal === 'editAircraft'}
        handleClose={handleCloseModal}
      />
      <TaskListModal
        taskData={taskData}
        isOpen={modal === 'taskList'}
        handleClose={handleCloseModal}
      />
      {loading ? <Loader color="black" /> : ''}
    </>
  );
}

const AdminBtn = ({ handleOpenModal }) => {
  return (
    <button
      onClick={handleOpenModal}
      className="z-10 absolute left-6 bottom-6 flex flex-col items-center justify-center text-gray-200 bg-slate-600/80 p-2 mr-4 aspect-square shadow-md rounded-lg"
    >
      <MdAdminPanelSettings size={25} />
    </button>
  );
};

const RefreshBtn = ({ loading, refresh }) => {
  return (
    <button
      onClick={() => refresh()}
      className="z-10 absolute top-[70px] right-[calc(0.25rem+35px)] h-[30px] w-[30px] flex items-center justify-center bg-black/50 text-white text-sm font-bold px-1 rounded-md"
      disabled={loading}
    >
      <BiRefresh size={28} className={`${loading ? 'animate-spin' : ''} `} />
    </button>
  );
};

const ExportToPDF = ({ acftData, docData, dutyOps }) => {
  const { dailyDocuments, dailyAircraft } = useContext(FirestoreContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);

  const handleOpenModal = () => {
    // If time is before 14:00, don't allow PDF generation, show toast message
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    if (currentHour < 12) {
      setToastMessage('PDF generation is only available after 12:00.');
      setToastType('error');
      return;
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    try {
      await handleGenerateAndSendPDF(
        acftData,
        docData,
        dailyDocuments,
        dailyAircraft,
        dutyOps
      );
      setToastMessage('PDF generated, uploaded, and email sent successfully!');
      setToastType('success');
    } catch (error) {
      console.error('Error generating, uploading, or sending PDF:', error);
      setToastMessage('An error occurred. Please try again.');
      setToastType('error');
    }
  };

  const closeToast = () => {
    setToastMessage(null);
    setToastType(null);
  };

  return (
    <>
      <button
        onClick={handleOpenModal}
        className="z-10 absolute top-[70px] right-[calc(0.25rem+70px)] h-[30px] w-[30px] flex items-center justify-center bg-black/50 text-white text-sm font-bold px-1 rounded-md"
      >
        <BsFillFileEarmarkPdfFill />
      </button>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message="Are you sure you want to send the PDF?"
      />

      {toastMessage && (
        <Toast message={toastMessage} type={toastType} onClose={closeToast} />
      )}
    </>
  );
};

const NoAccess = () => {
  const [loading, setLoading] = useState(true);
  setTimeout(() => setLoading(false), 5000);

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img src="/logo.png" className="h-16" alt="center air logo" />
      <span className="flex text-xl font-bold">
        {loading ? 'Checking access...' : <>You do not have access!</>}
      </span>
    </div>
  );
};

const Logout = ({ logout }) => {
  return (
    <button
      onClick={logout}
      className="z-10 absolute top-[70px] right-[calc(0.25rem)] h-[30px] w-[30px] flex items-center justify-center bg-black/50 text-white text-sm font-bold px-1 rounded-md"
    >
      <MdLock size={18} />
    </button>
  );
};

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-md p-4 shadow-lg max-w-sm w-full">
        <p className="text-[#194668] text-center text-lg mb-4">{message}</p>
        <div className="flex justify-around">
          <button
            onClick={onClose}
            className="bg-[#E9F0F4] text-[#6593B5] font-bold py-2 px-4 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-[#6593B5] text-white font-bold py-2 px-4 rounded-md"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const Toast = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true); // Start slide-in animation

    const timer = setTimeout(() => {
      setIsVisible(false); // Start slide-out animation
      const closeTimer = setTimeout(onClose, 300); // Delay closing to allow slide-out animation to complete
      return () => clearTimeout(closeTimer);
    }, 3000); // Duration the toast is visible

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`fixed top-0 inset-x-0 z-50 flex items-center justify-center`}>
      <div
        className={`mt-4 px-4 py-2 rounded-md shadow-md ${
          isVisible ? 'animate-slide-in' : 'animate-slide-out'
        } ${type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white`}
      >
        {message}
      </div>
    </div>
  );
};
